import type { WorkflowObject } from '@newdaycards/spec-tools';
import type { ExpandableItemType } from 'components/markdown/expandable-card';
import Hero from 'components/markdown/hero';
import type { PageProps } from 'gatsby';
import withAppInsights from 'utils/analytics/appinsights';
import { PageLayout } from '../layouts/page-layout';
import { WorkflowLayout } from '../layouts/workflow';

export interface WorkflowPageContext {
  workflow: WorkflowObject;
  title: string;
  description: string;
  commitDate: number;
}

export function WorkflowTemplate({ pageContext, location }: PageProps<null, WorkflowPageContext>) {
  const { title, description, workflow, commitDate } = pageContext;

  return (
    <PageLayout location={location} isGreyLayout title={title} description={description} commitDate={commitDate}>
      <Hero
        type="small"
        tag={(workflow.tag as ExpandableItemType) || 'E2E'}
        headline={workflow.summary}
        copy={workflow.description}
        image={{ name: 'stepcards-hero.svg', nameDark: 'stepcards-hero-dark.svg', altText: 'Small' }}
      />
      <WorkflowLayout workflow={workflow} />
    </PageLayout>
  );
}

export default withAppInsights(WorkflowTemplate);
