import * as TabsPrimitive from '@radix-ui/react-tabs';
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from 'react';
import { cn } from 'utils/cn';

const Tabs = TabsPrimitive.Root;
const TabsList = forwardRef<ElementRef<typeof TabsPrimitive.List>, ComponentPropsWithoutRef<typeof TabsPrimitive.List>>(
  ({ className, ...props }, ref) => (
    <TabsPrimitive.List
      {...props}
      ref={ref}
      className={cn(
        'flex border border-neutral-400 rounded-lg bg-gray-100 dark:bg-dark-100 dark:border-dark-100',
        className,
      )}
    />
  ),
);
const TabContent = TabsPrimitive.Content;

const TabTrigger = forwardRef<
  ElementRef<typeof TabsPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      'flex items-center gap-2 px-4 py-2 text-sm font-medium',
      'text-neutral-600 dark:text-dark-50 data-[state=active]:dark:text-dark-900  hover:text-gray-900',
      'data-[state=active]:bg-neutral-200 dark:bg-dark-800 data-[state=active]:dark:bg-dark-100 bg-white data-[state=active]:text-gray-900',
      'first:rounded-l-lg border-neutral-400 dark:border-dark-100 last:rounded-r-lg',
      className,
    )}
    {...props}
  />
));

export { TabContent, TabTrigger, Tabs, TabsList };
