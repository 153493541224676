import DOMPurify from 'isomorphic-dompurify';

const markdownRegex = /(^#{1,6} .*$)|(\*\*.*\*\*)|(\*.*\*)|(^- .*$)|(^\d+\. .*$)|(\n$)|(^(<li>[\s\S]*<\/li>)$)/gm;
const htmlRegex = /<\/?[a-z][\s\S]*>/i;
const ALLOWED_TAGS = ['b', 'i', 'em', 'strong', 'br', 'ul', 'ol', 'li'];

export const markdownToHtml = (markdown: string) =>
  markdown
    .trim()
    .replace(/^#{1,6} (.*$)/gim, (match, group1) => `<b>${group1}</b>`)
    .replace(/^- (.*$)/gim, '<li>$1</li>')
    .replace(/^#{1,6} (.*$)/gim, '<b>$1</b>')
    .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
    .replace(/\*(.*?)\*/g, '<i>$1</i>')
    .replace(/(<li>.*?<\/li>)/gis, '<ul>$1</ul>')
    .replace(/\\n/g, '<br>');

export const renderDescription = (description: string) => {
  if (!htmlRegex.test(description) && !markdownRegex.test(description)) {
    return description;
  }

  if (htmlRegex.test(description)) {
    return (
      <span
        className="[&_ul]:ml-4 [&_ol]:ml-4 [&_ul]:list-disc [&_ol]:list-decimal"
        // biome-ignore lint/security/noDangerouslySetInnerHtml: using sanitizeHtml
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(description, {
            ALLOWED_TAGS,
          }),
        }}
      />
    );
  }

  return (
    <span
      className="[&_ul]:ml-4 [&_ol]:ml-4 [&_ul]:list-disc [&_ol]:list-decimal"
      // biome-ignore lint/security/noDangerouslySetInnerHtml: using sanitizeHtml
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(markdownToHtml(description), {
          ALLOWED_TAGS,
        }),
      }}
    />
  );
};
